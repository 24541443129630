import * as React from 'react';

import { useBridgeResource } from '../bridge';

const PreRegistration_Header_Region: React.FC = () => {
  const { region } = useBridgeResource();

  return (
    <>
      {region.name3}에
    </>
  );
};

export default PreRegistration_Header_Region;

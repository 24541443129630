import * as React from 'react';

import * as styles from './Badge.css';

type Props = {
  text: string,
};

const Badge: React.FC<Props> = ({
  text,
}) => {
  return <span className={styles.root}>{text}</span>;
};

export default Badge;

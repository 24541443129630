import * as React from 'react';
import { graphql } from 'gatsby';

import * as styles from './PrismicMiniappContentArticleBlock.css';

type Props = {
  slice: GatsbyTypes.PrismicMiniappContentArticleBlock_sliceFragment,
};

export const fragments = graphql`
  fragment PrismicMiniappContentArticleBlock_slice on PrismicMiniappContentDataBodyArticleBlock {
    primary {
      section_title {
        text
      }
      section_body {
        html
      }
    }
  }
`;

const PrismicMiniappContentArticleBlock: React.FC<Props> = ({
  slice: {
    primary: {
      section_title: {
        text: title,
      } = {},
      section_body: {
        html: body,
      } = {},
    },
  },
}) => {
  return (
    <section className={styles.root}>
      {title && (
        <h3 className={styles.title}>
          {title}
        </h3>
      )}
      {body && (
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </section>
  );
};

export default PrismicMiniappContentArticleBlock;

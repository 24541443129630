import * as React from 'react';
import { graphql } from 'gatsby';

import AcceptButton from './PreRegistration_CancelConfirmModal_AcceptButton';
import RejectButton from './PreRegistration_CancelConfirmModal_RejectButton';

import * as styles from './PreRegistration_CancelConfirmModal.css';

type Props = {
  preRegistration: GatsbyTypes.PreRegistration_CancelConfirmModal_preRegistrationFragment,
  onClose: VoidFunction,
};

export const query = graphql`
  fragment PreRegistration_CancelConfirmModal_preRegistration on MarketplaceMiniappPreRegistration {
    ...PreRegistration_CancelConfirmModal_AcceptButton_preRegistration
  }
`;

const PreRegistration_CancelConfirmModal: React.FC<Props> = ({
  preRegistration,
  onClose,
}) => {
  return (
    <div className={styles.root}>
      <div
        className={styles.overlay}
        onClick={onClose}
      >
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className={styles.message}>
            지금 취소하면 다시 신청 할 수 없어요. 그래도 취소하시겠어요?
          </p>
          <div className={styles.buttons}>
            <AcceptButton
              preRegistration={preRegistration}
              onClose={onClose}
            />
            <RejectButton onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRegistration_CancelConfirmModal;

import * as React from 'react';
import { graphql } from 'gatsby';

type Props = {
  slice: GatsbyTypes.PrismicMiniappContentImageSliderBlock_sliceFragment,
};

export const fragments = graphql`
  fragment PrismicMiniappContentImageSliderBlock_slice on PrismicMiniappContentDataBodyImageSliderBlock {
    items {
      image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

const PrismicMiniappContentImageSliderBlock: React.FC<Props> = ({
  slice,
}) => {
  console.log('TODO', slice);
  return null;
};

export default PrismicMiniappContentImageSliderBlock;

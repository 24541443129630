import * as React from 'react';
import { graphql } from 'gatsby';
import { mapUnion } from '@cometjs/graphql-utils';

import PrismicMiniappContentArticleBlock from './PrismicMiniappContentArticleBlock';

import * as styles from './PreRegistration_Body.css';
import PrismicMiniappContentImageBlock from './PrismicMiniappContentImageBlock';
import PrismicMiniappContentImageSliderBlock from './PrismicMiniappContentImageSliderBlock';
import PrismicMiniappContentYoutubeBlock from './PrismicMiniappContentYoutubeBlock';

type Props = {
  content: GatsbyTypes.PreRegistration_Body_contentFragment,
};

export const fragments = graphql`
  fragment PreRegistration_Body_content on PrismicMiniappContent {
    data {
      app_name

      body {
        __typename
        ...PrismicMiniappContentArticleBlock_slice
        ...PrismicMiniappContentImageBlock_slice
        ...PrismicMiniappContentImageSliderBlock_slice
        ...PrismicMiniappContentYoutubeBlock_slice
      }
    }
  }
`;

function hasJong(text: string) {
  const code = text.charCodeAt(text.length - 1);
  return (code - 0xac00) % 28 > 0;
}

const PreRegistration_Body: React.FC<Props> = ({
  content: {
    data: {
      app_name: name = '',
      body = [],
    },
  },
}) => {
  return (
    <main className={styles.root}>
      <div className={styles.titlebox}>
        <h2 className={styles.title}>
          {name}{hasJong(name) ? '은' : '는'} 이런 서비스예요.
        </h2>
      </div>
      <div className={styles.contentbox}>
        {body.map((slice, i) => mapUnion(slice, {
          PrismicMiniappContentDataBodyArticleBlock: slice => (
            <PrismicMiniappContentArticleBlock
              key={i}
              slice={slice}
            />
          ),
          PrismicMiniappContentDataBodyImageBlock: slice => (
            <PrismicMiniappContentImageBlock
              key={i}
              slice={slice}
            />
          ),
          PrismicMiniappContentDataBodyImageSliderBlock: slice => (
            <PrismicMiniappContentImageSliderBlock
              key={i}
              slice={slice}
            />
          ),
          PrismicMiniappContentDataBodyYoutubeBlock: slice => (
            <PrismicMiniappContentYoutubeBlock
              key={i}
              slice={slice}
            />
          ),
        }))}
      </div>
    </main>
  );
};

export default PreRegistration_Body;

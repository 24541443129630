import * as React from 'react';

import * as styles from './PreRegistration_Header_RegionPlaceholder.css';

const PreRegistration_Header_RegionPlaceholder: React.FC = () => {
  return (
    <div className={styles.root} />
  );
};

export default PreRegistration_Header_RegionPlaceholder;

import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Option } from '@cometjs/core';

import * as styles from './PrismicMiniappContentImageBlock.css';

type Props = {
  slice: GatsbyTypes.PrismicMiniappContentImageBlock_sliceFragment,
};

export const fragments = graphql`
  fragment PrismicMiniappContentImageBlock_slice on PrismicMiniappContentDataBodyImageBlock {
    primary {
      section_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 640
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
  }
`;

const PrismicMiniappContentImageBlock: React.FC<Props> = ({
  slice: {
    primary: {
      section_image: {
        alt,
        localFile,
      } = {},
    },
  },
}) => {
  const image = Option.map(
    localFile?.childImageSharp?.gatsbyImageData,
    getImage,
  );

  return Option.map(image, image => (
    <section className={styles.root}>
      <GatsbyImage
        image={image}
        loading="lazy"
        alt={alt || ''}
      />
    </section>
  ));
};

export default PrismicMiniappContentImageBlock;

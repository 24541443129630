import * as React from 'react';
import { graphql } from 'gatsby';

import { useCancelPreRegistrationMutation } from '../__generated__/sdk';
import { useBridgeResource } from '../bridge';

import * as styles from './PreRegistration_CancelConfirmModal_AcceptButton.css';

type Props = {
  preRegistration: (
    GatsbyTypes.PreRegistration_CancelConfirmModal_AcceptButton_preRegistrationFragment
  ),
  onClose: VoidFunction,
};

export const fragments = graphql`
fragment PreRegistration_CancelConfirmModal_AcceptButton_preRegistration on MarketplaceMiniappPreRegistration {
  miniId
}
`;

const PreRegistration_CancelConfirmModal_AcceptButton: React.FC<Props> = ({
  preRegistration: {
    miniId,
  },
  onClose,
}) => {
  const { user, region } = useBridgeResource();
  const [_cancelResponse, cancelPreRegistration] = useCancelPreRegistrationMutation();
  const fetchOptions: RequestInit = {
    headers: {
      'X-Auth-Token': user.authToken,
    },
  };

  return (
    <button
      className={styles.root}
      onClick={() => {
        cancelPreRegistration(
          { regionId: region.id.toString(), preRegistartionId: miniId },
          { fetchOptions },
        );
        onClose();
      }}
    >
      <span>
        네, 취소할래요.
      </span>
    </button>
  );
};

export default PreRegistration_CancelConfirmModal_AcceptButton;

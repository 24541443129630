import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Option } from '@cometjs/core';

import Badge from './Badge';

import * as styles from './PreRegistration_Header_Banner.css';

type Props = {
  data: GatsbyTypes.PreRegistration_Header_Banner_dataFragment,
};

export const fragments = graphql`
  fragment PreRegistration_Header_Banner_data on PrismicMiniappContentDataType {
    catchphrase
    app_name
    app_icon {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 64
            height: 64
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

const PartnerPreRegistration_Header_Banner: React.FC<Props> = ({
  data: {
    app_name: name,
    app_icon: icon,
    catchphrase,
  },
}) => {
  const image = Option.map(icon?.localFile?.childImageSharp?.gatsbyImageData, getImage);

  return (
    <section className={styles.root}>
      <div>
        {Option.map(image, image => (
          <div className={styles.imagewrapper}>
            <GatsbyImage
              image={image}
              alt={`${name} 로고`}
            />
          </div>
        ),
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.titlebox}>
          <span className={styles.title}>
            {name}
          </span>
          <Badge text="mini" />
        </div>
        <p className={styles.catchphrase}>
          {catchphrase}
        </p>
      </div>
    </section>
  );
};

export default PartnerPreRegistration_Header_Banner;

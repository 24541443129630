import * as React from 'react';

import * as styles from './PreRegistration_CancelConfirmModal_RejectButton.css';

type Props = {
  onClose: VoidFunction,
};

const PreRegistration_CancelConfirmModal_RejectButton: React.FC<Props> = ({
  onClose,
}) => {
  return (
    <button
      className={styles.root}
      onClick={onClose}
    >
      <span>
        아니요, 오픈알림 받을래요.
      </span>
    </button>
  );
};

export default PreRegistration_CancelConfirmModal_RejectButton;

import * as React from 'react';
import { graphql } from 'gatsby';
import { ErrorBoundary } from 'react-error-boundary';

import Banner from './PreRegistration_Header_Banner';
import Region from './PreRegistration_Header_Region';
import RegionPlaceholder from './PreRegistration_Header_RegionPlaceholer';

import * as styles from './PreRegistration_Header.css';

type Props = {
  content: GatsbyTypes.PreRegistration_Header_contentFragment,
};

export const query = graphql`
  fragment PreRegistration_Header_content on PrismicMiniappContent {
    data {
      ...PreRegistration_Header_Banner_data
      app_name
    }
  }
`;

const PartnerPreRegistration_Header: React.FC<Props> = ({
  content,
  content: {
    data: {
      app_name: name = '',
    },
  },
}) => {
  return (
    <header className={styles.root}>
      <div className={styles.titlebox}>
        <h1 className={styles.title}>
          <div className={styles.region}>
            <ErrorBoundary fallback={<>우리 동네에</>}>
              <React.Suspense fallback={<RegionPlaceholder />}>
                <Region />
              </React.Suspense>
            </ErrorBoundary>
          </div>
          {name} 서비스가 곧 열려요!
        </h1>
      </div>
      <Banner data={content.data} />
    </header>
  );
};

export default PartnerPreRegistration_Header;

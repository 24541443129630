import * as React from 'react';
import { graphql } from 'gatsby';

import * as styles from './PrismicMiniappContentYoutubeBlock.css';

type Props = {
  slice: GatsbyTypes.PrismicMiniappContentYoutubeBlock_sliceFragment,
};

export const fragments = graphql`
  fragment PrismicMiniappContentYoutubeBlock_slice on PrismicMiniappContentDataBodyYoutubeBlock {
    primary {
      youtube_link {
        html_enablejsapi
      }
    }
  }
`;

const PrismicMiniappContentYoutubeBlock: React.FC<Props> = ({
  slice: {
    primary: {
      youtube_link: {
        html_enablejsapi: html,
      } = {},
    },
  },
}) => {
  if (!html) {
    console.error('youtube_link 값이 없습니다.');
    return null;
  }

  return (
    <div
      className={styles.root}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default PrismicMiniappContentYoutubeBlock;

import * as React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { required } from '@cometjs/core';
import { ErrorBoundary } from 'react-error-boundary';

import { ClientOnly } from '../rendering';

import Layout from '../components/Layout';
import AppBar from '../components/AppBar';
import Header from '../components/PreRegistration_Header';
import Body from '../components/PreRegistration_Body';
import ConfirmModal from '../components/PreRegistration_CancelConfirmModal';
import WebViewAttachment from '../components/WebViewAttachment';

const Action = React.lazy(() => import('../components/PreRegistration_Action'));

import * as styles from './PartnerPreRegistrationPage.css';

type Props = PageProps<(
  GatsbyTypes.PartnerPreRegistrationPageQuery
)>;

export const query = graphql`
  query PartnerPreRegistrationPage(
    $miniId: String!,
    $prismicId: ID!,
  ) {
    content: prismicMiniappContent(
      prismicId: { eq: $prismicId }
    ) {
      prismicId
      ...PreRegistration_Header_content
      ...PreRegistration_Body_content
    },
    preRegistration: marketplaceMiniappPreRegistration (
      miniId: { eq: $miniId }
    ) {
      miniId
      status
      ...PreRegistration_Action_preRegistration
      ...PreRegistration_CancelConfirmModal_preRegistration
    }
  }
`;

const PartnerPreRegistrationPage: React.FC<Props> = ({
  data: {
    content,
    preRegistration,
  },
}) => {
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);
  required(content);
  required(preRegistration);

  const openModal = () => {
    setModalStatus(true);
  };

  const closeModal = () => {
    setModalStatus(false);
  };

  return (
    <Layout>
      <div className={styles.root}>
        <AppBar />
        <article
          className={styles.content}
          id="miniapp-pre-registration-content"
          data-mini-id={preRegistration.miniId}
          data-pre-registration-status={preRegistration.status}
          data-prismic-id={content.prismicId}
        >
          <Header content={content} />
          <Body content={content} />
        </article>

        <ClientOnly>
          <ErrorBoundary fallback={<></>}>
            <React.Suspense fallback={null}>
              <Action
                preRegistration={preRegistration}
                onOpen={openModal}
              />
            </React.Suspense>
          </ErrorBoundary>
        </ClientOnly>

        {modalStatus &&
          <ConfirmModal
            preRegistration={preRegistration}
            onClose={closeModal}
          />
        }

        <WebViewAttachment />
      </div>
    </Layout>
  );
};

export default PartnerPreRegistrationPage;

import { gql } from 'urql';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
};

export type ApprovePaymentInput = {
  /** 거래 ID */
  transactionId: Scalars['String'];
};

export type ApprovePaymentOutput = ApprovePaymentOutput_Result | PaymentError;

export type ApprovePaymentOutput_Result = {
  result: ApprovePaymentOutput_ResultPayload;
};

export type ApprovePaymentOutput_ResultPayload = {
  transactionId: Scalars['String'];
};

export type CancelMiniappPreRegistrationInput = {
  miniappPreRegistrationId: Scalars['ID'];
  regionId: Scalars['String'];
};

export type CancelMiniappPreRegistrationOutput = CancelMiniappPreRegistrationOutput_Result;

export type CancelMiniappPreRegistrationOutput_Result = {
  result: CancelMiniappPreRegistrationOutput_ResultPayload;
};

export type CancelMiniappPreRegistrationOutput_ResultPayload = {
  miniappPreRegistration: MiniappPreRegistration;
};

export type ChannelInput = {
  id: Scalars['Int'];
  text: Scalars['String'];
  url: Scalars['URL'];
};

export type Chat = {
  channelInput: Maybe<ChannelInput>;
};

export type ChatChannelInput = {
  text: Scalars['String'];
  url: Scalars['URL'];
};

export type EndedMiniappPreRegistrationsConnection = {
  edges: Array<EndedMiniappPreRegistrationsEntryEdge>;
  nodes: Array<MiniappPreRegistration>;
  pageInfo: PageInfo;
};

export type EndedMiniappPreRegistrationsEntryEdge = {
  cursor: Scalars['String'];
  node: MiniappPreRegistration;
};

export type ExposureRegionInfoInput = {
  isExposed: Scalars['Boolean'];
  regionId: Scalars['String'];
};

export type Image = {
  /** 높이 */
  height: Scalars['Int'];
  /** 원본 URL */
  url: Scalars['URL'];
  /** 가로 길이 */
  width: Scalars['Int'];
};

export type Miniapp = Node & {
  /** 평균 리뷰 점수. 리뷰가 없다면 Null */
  averageReviewScore: Maybe<Scalars['Float']>;
  /** 채팅 */
  chat: Chat;
  /** 미니앱 설명 */
  description: Scalars['String'];
  /** 진입 URL */
  entryUrl: Scalars['URL'];
  /** 아이콘 이미지 */
  iconImage: Image;
  id: Scalars['ID'];
  /** 미니앱 이름 */
  name: Scalars['String'];
  /** 제휴사 */
  partner: Maybe<Partner>;
  /** 전체 리뷰 수 */
  totalReviewCount: Scalars['Int'];
  /** 전체 구독 수 */
  totalSubscriptionCount: Scalars['Int'];
};


export type MiniappIconImageArgs = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type MiniappEdge = {
  cursor: Scalars['String'];
  node: Miniapp;
};

export type MiniappInput = {
  publicId: Scalars['String'];
};

export type MiniappListConnection = {
  edges: Array<MiniappListEntryEdge>;
  nodes: Array<MiniappListEntry>;
  pageInfo: PageInfo;
};

export type MiniappListEntry = Miniapp | MiniappPreRegistration;

export type MiniappListEntryEdge = {
  cursor: Scalars['String'];
  node: MiniappListEntry;
};

export type MiniappPreRegistration = Node & {
  /** 미니앱 설명 */
  description: Scalars['String'];
  endsAt: Scalars['DateTime'];
  /** 진입 URL */
  entryUrl: Scalars['URL'];
  /** 아이콘 이미지 */
  iconImage: Image;
  id: Scalars['ID'];
  /** 미니앱 이름 */
  name: Scalars['String'];
  /** 제휴사 */
  partner: Maybe<Partner>;
  /** 사전 등록 수 */
  preRegistrationCount: Scalars['Int'];
  /** 사전 등록을 신청한 유저들 */
  registrants: Array<Registrant>;
  startsAt: Scalars['DateTime'];
  /** 미니앱 사전등록의 상태 */
  status: MiniappPreRegistrationStatus;
  /** Header에 Viewer context가 없으면 null을 반환 */
  viewer: Maybe<MiniappPreRegistrationViewer>;
};


export type MiniappPreRegistrationIconImageArgs = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type MiniappPreRegistrationEdge = {
  cursor: Scalars['String'];
  node: MiniappPreRegistration;
};

export type MiniappPreRegistrationStatus =
  | 'CLOSED'
  | 'NOT_OPEN_YET'
  | 'ONGOING';

export type MiniappPreRegistrationViewer = {
  /** 유저의 사전등록 여부. parent로부터 regionId가 있는 경우에만 판단하며 없는 경우에는 false를 반환한다. */
  registered: Scalars['Boolean'];
};


export type MiniappPreRegistrationViewerRegisteredArgs = {
  regionId: InputMaybe<Scalars['String']>;
};

export type MiniappPreRegistrationsConnection = {
  edges: Array<MiniappPreRegistrationEdge>;
  nodes: Array<MiniappPreRegistration>;
  pageInfo: PageInfo;
};

export type MiniappsConnection = {
  edges: Array<MiniappEdge>;
  nodes: Array<Miniapp>;
  pageInfo: PageInfo;
};

export type Mutation = {
  /** 결제를 승인해요 */
  approvePayment: ApprovePaymentOutput;
  /** 미니앱 사전신청을 취소합니다 */
  cancelMiniappPreRegistration: CancelMiniappPreRegistrationOutput;
  /** 결제를 준비해요 */
  preparePayment: PreparePaymentOutput;
  /** 결제를 환불해요 */
  refundPayment: RefundPaymentOutput;
  /** 미니앱 사전신청을 요청합니다 */
  requestMiniappPreRegistration: RequestMiniappPreRegistrationOutput;
  /** 사전신청한 미니앱이 오픈되었다는 알림 발송 */
  sendMiniappPreRegistrationOpenNotification: SendMiniappPreRegistrationOpenNotificationOutput;
  /** 사전신청한 미니앱이 오픈되었다는 테스트 알림 발송 */
  sendTestMiniappPreRegistrationOpenNotification: SendMiniappPreRegistrationOpenNotificationOutput;
  /** 채팅의 채널 Input 설정 */
  setChatChannelInput: ChannelInput;
  /** 미니앱 노출 지역 업데이트 */
  updateMiniappExposureRegions: UpdateExposureRegionsOutput;
  /** 미니앱 사전등록 노출 지역 업데이트 */
  updateMiniappPreRegistrationExposureRegions: UpdateExposureRegionsOutput;
};


export type MutationApprovePaymentArgs = {
  input: ApprovePaymentInput;
};


export type MutationCancelMiniappPreRegistrationArgs = {
  input: CancelMiniappPreRegistrationInput;
};


export type MutationPreparePaymentArgs = {
  input: PreparePaymentInput;
};


export type MutationRefundPaymentArgs = {
  input: RefundPaymentInput;
};


export type MutationRequestMiniappPreRegistrationArgs = {
  input: RequestMiniappPreRegistrationInput;
};


export type MutationSendMiniappPreRegistrationOpenNotificationArgs = {
  miniappPreRegistrationId: Scalars['String'];
  regionId: Scalars['String'];
};


export type MutationSendTestMiniappPreRegistrationOpenNotificationArgs = {
  karrotUserId: Scalars['String'];
  miniappPreRegistrationId: Scalars['String'];
  regionId: Scalars['String'];
};


export type MutationSetChatChannelInputArgs = {
  chatChannelInput: ChatChannelInput;
  miniappId: Scalars['String'];
};


export type MutationUpdateMiniappExposureRegionsArgs = {
  exposureRegionInfos: Array<ExposureRegionInfoInput>;
  miniappId: Scalars['String'];
};


export type MutationUpdateMiniappPreRegistrationExposureRegionsArgs = {
  exposureRegionInfos: Array<ExposureRegionInfoInput>;
  miniappPreRegistrationId: Scalars['String'];
};

export type Node = {
  /** 스키마에서 유일한 ID 값입니다 (Global Object Identification) */
  id: Scalars['ID'];
};

export type NotificationSendedRegion = {
  fullName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type NotificationSendedRegionResult = {
  region: NotificationSendedRegion;
  sentCount: Scalars['Int'];
};

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor: Maybe<Scalars['String']>;
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor: Maybe<Scalars['String']>;
};

export type Partner = Node & {
  id: Scalars['ID'];
  name: Scalars['String'];
  publicId: Scalars['String'];
};

export type PaymentError = {
  paymentError: PaymentErrorPayload;
};

export type PaymentErrorPayload = {
  message: Scalars['String'];
};

export type PreparePaymentInput = {
  /** 금액 */
  amount: Scalars['Int'];
  /** 설명 */
  description: Scalars['String'];
};

export type PreparePaymentOutput = PaymentError | PreparePaymentOutput_Result;

export type PreparePaymentOutput_Result = {
  result: PreparePaymentOutput_ResultPayload;
};

export type PreparePaymentOutput_ResultPayload = {
  transactionId: Scalars['String'];
};

export type Query = {
  miniapp: Maybe<Miniapp>;
  miniappPreRegistration: Maybe<MiniappPreRegistration>;
  miniappPreRegistrations: MiniappPreRegistrationsConnection;
  miniapps: MiniappsConnection;
  /** ID 값으로 Object를 가져옵니다 (Global Object Identification) */
  node: Maybe<Node>;
  region: Maybe<Region>;
  regions: RegionListConnection;
  transaction: Maybe<Transaction>;
};


export type QueryMiniappArgs = {
  input: MiniappInput;
};


export type QueryMiniappPreRegistrationArgs = {
  id: Scalars['ID'];
};


export type QueryMiniappPreRegistrationsArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryMiniappsArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryRegionArgs = {
  filter: RegionFilter;
};


export type QueryRegionsArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
};

export type RefundPaymentInput = {
  /** 환불할 금액 */
  amount: InputMaybe<Scalars['Int']>;
  /** 거래 ID */
  transactionId: Scalars['String'];
};

export type RefundPaymentOutput = PaymentError | RefundPaymentOutput_Result;

export type RefundPaymentOutput_Result = {
  result: RefundPaymentOutput_ResultPayload;
};

export type RefundPaymentOutput_ResultPayload = {
  transactionId: Scalars['String'];
};

export type Region = Node & {
  endedMiniappPreRegistrations: EndedMiniappPreRegistrationsConnection;
  exposedMiniapps: MiniappListConnection;
  /** 행정동 이름. e.g. 서울시 서초구 서초동 */
  fullName: Scalars['String'];
  id: Scalars['ID'];
  /** 동네 이름. e.g. 서초동 */
  name: Scalars['String'];
  publicId: Scalars['String'];
};


export type RegionEndedMiniappPreRegistrationsArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};


export type RegionExposedMiniappsArgs = {
  after: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};

export type RegionFilter = {
  publicId: Scalars['String'];
};

export type RegionListConnection = {
  edges: Array<RegionListEdge>;
  nodes: Array<Region>;
  pageInfo: PageInfo;
};

export type RegionListEdge = {
  cursor: Scalars['String'];
  node: Region;
};

export type Registrant = {
  /** 예전에 프로필을 등록한 유저의 경우 width/height가 변경되지 않을 수 있음 */
  profileImage: Image;
  /** 요청 ID */
  requestId: Scalars['String'];
};


export type RegistrantProfileImageArgs = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type RequestMiniappPreRegistrationInput = {
  miniappPreRegistrationId: Scalars['ID'];
  regionId: Scalars['String'];
};

export type RequestMiniappPreRegistrationOutput = RequestMiniappPreRegistrationOutput_Result;

export type RequestMiniappPreRegistrationOutput_Result = {
  result: RequestMiniappPreRegistrationOutput_ResultPayload;
};

export type RequestMiniappPreRegistrationOutput_ResultPayload = {
  miniappPreRegistration: MiniappPreRegistration;
};

export type SendMiniappPreRegistrationOpenNotificationOutput = SendMiniappPreRegistrationOpenNotificationOutput_FailedPreconditionError | SendMiniappPreRegistrationOpenNotificationOutput_Result;

export type SendMiniappPreRegistrationOpenNotificationOutput_FailedPreconditionError = {
  failedPreconditionError: SendMiniappPreRegistrationOpenNotificationOutput_FailedPreconditionErrorPayload;
};

export type SendMiniappPreRegistrationOpenNotificationOutput_FailedPreconditionErrorPayload = {
  message: Scalars['String'];
};

export type SendMiniappPreRegistrationOpenNotificationOutput_Result = {
  result: SendMiniappPreRegistrationOpenNotificationOutput_ResultPayload;
};

export type SendMiniappPreRegistrationOpenNotificationOutput_ResultPayload = {
  notificationSendedRegions: Array<NotificationSendedRegionResult>;
};

export type Transaction = {
  /** 설명 */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** 마지막 환불 시각 */
  lastRefundedAt: Maybe<Scalars['DateTime']>;
  /** 결제한 MiniappUserId */
  miniappUserId: Scalars['String'];
  /** 환불 금액 */
  refundAmount: TransactionAmount;
  /** 결제 상태 */
  status: TransactionStatus;
  /** 결제 시각 */
  transactedAt: Scalars['DateTime'];
  /** 거래 금액 */
  transactionAmount: TransactionAmount;
};

export type TransactionAmount = {
  /** 면세 금액 (단위: 원) */
  taxFree: Scalars['Int'];
  /** 총 금액 (단위: 원) */
  total: Scalars['Int'];
  /** 부가세 (단위: 원) */
  vat: Scalars['Int'];
};

export type TransactionStatus =
  /** 승인 */
  | 'Approved'
  /** 전액 환불 */
  | 'FullyRefunded'
  /** 부분 환불 */
  | 'PartialRefunded';

export type UpdateExposureRegionsOutput = UpdateExposureRegionsOutput_Result;

export type UpdateExposureRegionsOutput_Result = {
  result: UpdateExposureRegionsOutput_ResultPayload;
};

export type UpdateExposureRegionsOutput_ResultPayload = {
  status: UpdateExposureRegionsResultStatus;
};

export type UpdateExposureRegionsResultStatus =
  /** 성공 */
  | 'OK';

export type PreRegistration_RequestStatusFragment = { id: string, status: MiniappPreRegistrationStatus, preRegistrationCount: number, registrants: Array<{ requestId: string, profileImage: { url: string } }>, viewer: { registered: boolean } | null };

export type PreRegistrationQueryVariables = Exact<{
  id: Scalars['ID'];
  regionId: Scalars['String'];
}>;


export type PreRegistrationQuery = { miniappPreRegistration: { id: string, status: MiniappPreRegistrationStatus, preRegistrationCount: number, registrants: Array<{ requestId: string, profileImage: { url: string } }>, viewer: { registered: boolean } | null } | null };

export type RequestPreRegistrationMutationVariables = Exact<{
  regionId: Scalars['String'];
  preRegistartionId: Scalars['ID'];
}>;


export type RequestPreRegistrationMutation = { requestMiniappPreRegistration: { result: { miniappPreRegistration: { id: string, status: MiniappPreRegistrationStatus, preRegistrationCount: number, registrants: Array<{ requestId: string, profileImage: { url: string } }>, viewer: { registered: boolean } | null } } } };

export type CancelPreRegistrationMutationVariables = Exact<{
  regionId: Scalars['String'];
  preRegistartionId: Scalars['ID'];
}>;


export type CancelPreRegistrationMutation = { cancelMiniappPreRegistration: { result: { miniappPreRegistration: { id: string, status: MiniappPreRegistrationStatus, preRegistrationCount: number, registrants: Array<{ requestId: string, profileImage: { url: string } }>, viewer: { registered: boolean } | null } } } };

export const PreRegistration_RequestStatusFragmentDoc = gql`
    fragment PreRegistration_requestStatus on MiniappPreRegistration {
  id
  status
  preRegistrationCount
  registrants {
    requestId
    profileImage(width: 24, height: 24) {
      url
    }
  }
  viewer {
    registered(regionId: $regionId)
  }
}
    `;
export const PreRegistrationDocument = gql`
    query PreRegistration($id: ID!, $regionId: String!) {
  miniappPreRegistration(id: $id) {
    ...PreRegistration_requestStatus
  }
}
    ${PreRegistration_RequestStatusFragmentDoc}`;

export function usePreRegistrationQuery(options: Omit<Urql.UseQueryArgs<PreRegistrationQueryVariables>, 'query'>) {
  return Urql.useQuery<PreRegistrationQuery>({ query: PreRegistrationDocument, ...options });
};
export const RequestPreRegistrationDocument = gql`
    mutation RequestPreRegistration($regionId: String!, $preRegistartionId: ID!) {
  requestMiniappPreRegistration(
    input: {regionId: $regionId, miniappPreRegistrationId: $preRegistartionId}
  ) {
    ... on RequestMiniappPreRegistrationOutput_Result {
      result {
        miniappPreRegistration {
          ...PreRegistration_requestStatus
        }
      }
    }
  }
}
    ${PreRegistration_RequestStatusFragmentDoc}`;

export function useRequestPreRegistrationMutation() {
  return Urql.useMutation<RequestPreRegistrationMutation, RequestPreRegistrationMutationVariables>(RequestPreRegistrationDocument);
};
export const CancelPreRegistrationDocument = gql`
    mutation CancelPreRegistration($regionId: String!, $preRegistartionId: ID!) {
  cancelMiniappPreRegistration(
    input: {regionId: $regionId, miniappPreRegistrationId: $preRegistartionId}
  ) {
    ... on CancelMiniappPreRegistrationOutput_Result {
      result {
        miniappPreRegistration {
          ...PreRegistration_requestStatus
        }
      }
    }
  }
}
    ${PreRegistration_RequestStatusFragmentDoc}`;

export function useCancelPreRegistrationMutation() {
  return Urql.useMutation<CancelPreRegistrationMutation, CancelPreRegistrationMutationVariables>(CancelPreRegistrationDocument);
};